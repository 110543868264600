import React, { useEffect, useState } from "react";
import casestudyBanner from "../../Images/casestudy/casestudy-bg.png";
import catapault from "../../Images/casestudy/catapult.png";
import Slider from "react-slick";
import linkites from "../../Images/casestudy/linkites.png";
import relibit from "../../Images/casestudy/relibit.png";
import sliderTwo from "../../Images/about/code-break-2.png";
import sliderThree from "../../Images/about/code-break.png";
import sliderFour from "../../Images/about/code-break-2.png";
import bannerBg from "../../Images/about/banner-bg.png";
import storyBg from "../../Images/about/story-bg.png";
import bottomBg from "../../Images/about/bottom-bg.png";
import bottomGroupbg from "../../Images/features/bottom-group-bg.png";
import { getcashstudy } from "../../Api";
const Casestudy = () => {
  const [casestudy, setcasestudy] = useState([]);
  let showcase = async () => {
    let data = await getcashstudy();
    setcasestudy(data);
  };
  useEffect(() => {
    showcase();
  });
  return (
    <>
      <section className="casestudy-banner">
        <div className="banner-img-wrapper">
          <div className="banner-img">
            <img src={casestudyBanner} />
          </div>
          <div className="banner-text">
            <h2>Case studies</h2>
            <p>
              Explore our collection of case studies to learn more about our
              innovative solutions and the results we've delivered
            </p>
          </div>
        </div>
      </section>
      <section className="case-study-wrapper">
        <div className="container">
          {casestudy.map((item, index) => {
            if (index % 2 == 0) {
              return (
                <div className="case-study-list ">
                  <div className="row align-items-center">
                    <div className="col-lg-6">
                      <div className="case-study-text">
                        <h4>{item.title}</h4>
                        {item.pdfurl ? (
                          <div className="read-more">
                            <a href={item.pdfurl}>Read More</a>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <img src={item.image} alt={item.title} />
                    </div>
                  </div>
                </div>
              );
            } else {
              return (
                <div className="case-study-list">
                  <div className="row case-study-block align-items-center">
                    <div className="col-lg-6">
                      <div className="case-study-text">
                        <h4>{item.title}</h4>
                        {item.pdfurl ? (
                          <div className="read-more">
                            <a href={item.pdfurl}>Read More</a>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <img src={item.image} />
                    </div>
                  </div>
                </div>
              );
            }
          })}
        </div>
      </section>
    </>
  );
};

export default Casestudy;
