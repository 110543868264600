import React from "react";
import insta from "../../Images/instagram.svg";
import facebook from "../../Images/facebook.svg";
import linkedin from "../../Images/linkedin.svg";
import twitter from "../../Images/twitter.svg";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <>
      <footer className="footer">
        <div className="container">
          <section className="bugfree">
            <div className="container">
              <div className="bugfree-inner">
                <div className="bugfree-text">
                  Go <span className="gradient-heading-lg-blue">BUG-FREE</span>{" "}
                  with us
                </div>
                <a href="https://app.buildpan.com/" className="Start-btn">
                  Start-Building
                </a>
                <a href="mailto:hello@builpan.com" className="Request-demo">
                  Request-Demo
                </a>
              </div>
            </div>
          </section>

          <div className="footer-inner">
            <div className=" Customizable Workflow-wrapper">
              <h4 className="Workflow-heading">
                Infinitely Customizable Workflow
              </h4>
              <p className="Workflow-para">
                Need more customization for your enterprise account? We got you.
                We’re always updating ourselves with new technologies to meet
                your development needs. If there’s a service that you’d like to
                see on this list, let us know or write to us at <a href="mailto:hello@buildpan.com">hello@buildpan.com</a>.
              </p>
              <div className="social-list">
                <p className="follow-us">Follow us</p>
                <a href="https://www.facebook.com/BuildPan/" className="facebook-bg">
                  <img src={facebook} />
                </a>
                <a href="https://twitter.com/buildpan?lang=en" className="twitter-bg">
                  <img src={twitter} />
                </a>
                <a href="https://www.instagram.com/buildpan/?igshid=1g7macyaxjmt&hl=en" className="instagram-bg">
                  <img src={insta} />
                </a>
                {/* <a href="https://www.linkedin.com/company/buildpan/?viewAsMember=true" className="linkedin-bg">
                  <img src={linkedin} />
                </a> */}
              </div>
            </div>
            <div className="footer-list-wrapper">
              <ul className="footer-list navbar-nav">
                <li>
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/terms-condition">Terms & Conditions </Link>
                </li>
                <li>
                  <Link to="/security">Security</Link>
                </li>
                <li>
                  <Link to="/contact-us ">Contact Us</Link>
                </li>
                <li>
                  <Link to="/faq">FAQ</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
