import React, { useEffect } from "react";
const PrivacyPolicy = () => {

  return (
    <>
     
    <section class="terms_conditions-wrapper">
        <div class="container">
            <div>
                <h1 class="heading">Privacy Policy</h1>
            </div>
            <p>Effective Date: 10/01/2020</p>
            <p>1. Introduction</p>
            <p>Thank you for using Softlaunch Pte Ltd ("we," "us," "our", or BuildPan). This Privacy Policy outlines how
                we collect, use, disclose, and protect your personal information. By accessing or using our services,
                you agree to the terms outlined in this policy.</p>
            <p>2. Information We Collect</p>
            <p>2.1. Personal Information:</p>
            <ul >
                <li>During account creation and usage, we may collect personal information such as name, email address,
                    and contact details.</li>
            </ul>
            <p>2.2. Usage Data:</p>
            <ul >
                <li>We collect information on how you interact with our platform, including log data, IP addresses, and
                    device information. </li>
            </ul>
            <p>3. How We Use Your Information</p>
            <p>3.1. Providing Services:</p>
            <ul>
                <li>We use your information to deliver, improve, and customize our CICD platform to meet your needs.
                </li>
            </ul>
            <p>3.2. Communication:</p>
            <ul>
                <li>We may contact you with important updates, newsletters, or promotional materials related to our
                    services.</li>
            </ul>
            <p>4. Information Sharing and Disclosure</p>
            <p>4.1. Third-Party Services:</p>
            <ul>
                <li>We may share your information with third-party service providers who assist us in delivering our
                    services.</li>
            </ul>
            <p>4.2. Legal Compliance:</p>
            <ul>
                <li>We may disclose your information in response to legal requests or to protect our rights, privacy,
                    safety, or property. </li>
            </ul>
            <p>5. Security</p>
            <p>We prioritize the security of your information and implement industry-standard measures to protect
                against unauthorized access, disclosure, alteration, or destruction.
            </p>
            <p>6. Your Choices</p>
            <p>You can review and update your personal information by accessing your account settings. You may also
                opt-out of promotional communications.
            </p>
            <p>7. Updates to this Privacy Policy</p>
            <p>We reserve the right to update this Privacy Policy periodically. Please review it regularly for any
                changes.</p>
            <p>8. Contact Us</p>
            <p>If you have any questions, concerns, or requests regarding this Privacy Policy, please contact us at <a
                    href="mailto:hello@buildpan.com">hello@buildpan.com</a>.</p>
        </div>
    </section>
    </>
  );
};

export default PrivacyPolicy;
