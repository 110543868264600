import React, { useEffect, useState } from "react";
import bannerImg from "../../Images/blog/blog-bg.png";
import cicdImg from "../../Images/blog/ci-cd.png";
import Slider from "react-slick";

import cicdSlide from "../../Images/blog/cicd-slide-thee.png";
import bottomGroupbg from "../../Images/features/bottom-group-bg.png";
import editorOne from "../../Images/blog/cicd-slide-one.png";
import editorTwo from "../../Images/blog/cicd-slide-two.png";
import editorThree from "../../Images/blog/cicd-slide-thee.png";
import { getblogbypage, removep } from "../../Api";
import { fetchProducts } from "../../store/ProductSlice";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

const Blog = () => {
  const [product, setproduct] = useState([]);
  const [pagenum, setpagenum] = useState(1);
  let [page, setpages] = useState("");
  let postsPerPage = 6;
  const getblogs = async (page) => {
    let blogs = await getblogbypage(page);
    setproduct(blogs.data);
    console.log(blogs.count);
    let count = blogs.count;
    let total = count / postsPerPage;
    setpages(total);
    console.log("total", total);
  };

  let updatepagenumber = (pages) => {
    setpagenum(pages);
    console.log(pages);
  };

  useEffect(() => {
    getblogs(pagenum);
  }, [pagenum]);

  const RightSlider = {
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 5000,
    arrows: false,
    dots: true,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <>
      <section className="blog-banner">
        <div className="banner-img-wrapper">
          <div className="banner-img">
            <img src={bannerImg} />
          </div>
          <div className="banner-text">
            <h2>Blogs</h2>
            <p>
              Explore our latest blog posts to stay up-to-date on industry news,
              gain valuable insights, and discover actionable tips to help you
              achieve your goals{" "}
            </p>
          </div>
        </div>
      </section>
      <section className="cicd-block">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <img src={cicdImg} />
            </div>
            <div className="col-lg-6">
              <ul>
                <li>
                  Applications | Automation | Building | BuildPan | CI/CD |
                  Combined Practices | Continuous Deployment | Continuous
                  Integration | Development | Operation Activities | Testing |
                  Uncategorized |
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="cicd-pipeline">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <h2>CI/CD Pipeline</h2>
              <p>
                The goal of the Continuous Integration and Continuous Deployment
                process is to establish a consistent and automated way to build,
                test, and deploy applications. Continuous Integration (CI) and
                Continuous Deployment (CD) play an important role in responding
                to user feedback and ship new application code to production
                quickly and securely. Also, with continuous user feedback
                frequent changes to the code have become quite easy resulting in
                better software or application. Continuous Deployment starts
                where the CI finishes. The CD automates the deployment of
                applications. …
              </p>
            </div>
            <div className="col-lg-6">
              <div className="cicd-slider">
                <h4>Editor's Pick</h4>
                <Slider {...RightSlider}>
                  {product.map((item, ids) => {
                    return (
                      <div>
                        <img src={item.image} />
                        <p>{item.title}</p>
                      </div>
                    );
                  })}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="article-wrapper">
        <div className="container">
          <h2>Recent Articles</h2>
          <div className="row">
            {product.map((item, index) => {
              return (
                <div className="col-lg-4" key={index}>
                  <Link className="card" to={`/blogView/` + item._id}>
                    <div className="article-img">
                      <img src={item.image} />
                    </div>
                    <div className="article-text">
                      <h4>{item.title}</h4>
                      <h6> {removep(item.content).substring(0, 60)}</h6>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
          <div className="blog-pagination">
            <nav aria-label="Page navigation example">
              <ul className="pagination">
                <li className="page-item">
                  <a className="page-link" href="#" aria-label="Previous">
                    <span aria-hidden="true">&laquo;</span>
                  </a>
                </li>
                {[...Array(page)].map(
                  (
                    _,
                    index // Assuming you want two pages
                  ) => (
                    <li key={index} className="page-item">
                      <Link
                        className="page-link"
                        onClick={() => updatepagenumber(index + 1)} // Assuming updatePageNumber is a function to handle page number updates
                      >
                        {index + 1}
                      </Link>
                    </li>
                  )
                )}
                {/* <li className="page-item">
                  <Link
                    className="page-link"
                    onClick={() => updatepagenumber(1)}
                  >
                    1
                  </Link>
                </li>
                <li className="page-item">
                  <Link
                    className="page-link"
                    onClick={() => updatepagenumber(2)}
                  >
                    2
                  </Link>
                </li> */}

                <li className="page-item">
                  <a className="page-link" href="#" aria-label="Next">
                    <span aria-hidden="true">&raquo;</span>
                  </a>
                </li>
              </ul>
              {/* {JSON.stringify(totalpage)} */}
            </nav>
          </div>
        </div>
      </section>
      <section className="stay-up-block">
        <img src={bottomGroupbg} className="group-bg" />
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5">
              <h2>Stay Up to Date</h2>
              <p>
                Register to our blog updates newsletter to receive the latest
                content in your inbox.
              </p>
            </div>
            <div className="col-lg-7">
              <div className="mail-input">
                <input type="email" placeholder="Enter your mail id" />
                <button>Submit</button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Blog;
