

import React, { useEffect } from "react";
import icselect from "../../Images/ic-source-code.svg";
import icselectapplication from "../../Images/ic-select-application.svg";
import icbuild from "../../Images/ic-build-application.svg";
import icdeployment from "../../Images/ic-deployment.svg";
import Slider from "react-slick";
import jugnoo from "../../Images/Jugnoo-01.png";
import Seractive01 from "../../Images/Seractive-01-1.png";
import Shirali from "../../Images/Shirali-01-1.png";
import toch from "../../Images/toch.png";
import Catapult from "../../Images/Catapult-01.png";
import Lgfit from "../../Images/Lgfit-01.png";
import Linkites from "../../Images/Linkites-01.png";
import LTI from "../../Images/LTI-01.png";

import Polishme from "../../Images/Polishme-01.png";
import { gettestimonail } from "../../Api";
import { useState } from "react";
const Home = () => {
  const [testimonail, settestimonail] = useState([]);
  let settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  const TestimonialsSlider = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
          adaptiveHeight: true,
        },
      },
    ],
  };

  let gettingtestimonail = async () => {
    let data = await gettestimonail();
    settestimonail(data);
  };
  useEffect(() => {
    gettingtestimonail();
  }, []);
  return (
    <>
      <section className="banner-wrapper" id="banner-wrapper">
        <div className="container">
          <div className="banner-inner">
            <div>
              <div className="banner-title">
                <h1 className="banner-title-text">
                  <span className="enhance enhanceText">BUILD</span>
                  <span className="your yourText">Your</span>
                  <span className="productivity productText">APPLICATION</span>
                </h1>
              </div>
              <div className="banner-buttons">
                <div className="banner-button-inner">
                  <a href="#" className="building-btn">
                    Start Building
                  </a>
                  <a href="#" className="building-btn">
                    Request Demo
                  </a>
                </div>
              </div>
              <p className="banner-description-text">
                A better way to build web & mobile applications An
                all-in-one-place solution for iOS, android and web development
                release workflow
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="buildpan-icon">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className=" buildpanicon_main_wrapper">
                <h1 className="gradient-heading-lg-blue">
                  Looking for BuildPan Implementation?
                </h1>
                <h2>
                  Whether you are a freelancer or small startup or big
                  enterprise, we are here to assist you.
                </h2>
                <div className="row">
                  <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 buildpan-card">
                    <div className=" buildpan_icons_inner">
                      <img
                        src={icselect}
                        alt="loading-image"
                        className="buildpan_icon"
                      />
                      <p className="buildpan_icon_text">
                        Connect Source code account
                      </p>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 buildpan-card">
                    <div className="buildpan_icons_inner">
                      <img
                        src={icselectapplication}
                        alt="loading-image"
                        className="buildpan_icon"
                      />
                      <p className="buildpan_icon_text"> Select Application</p>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 buildpan-card">
                    <div className=" buildpan_icons_inner">
                      <img
                        src={icbuild}
                        alt="loading-image"
                        className="buildpan_icon"
                      />
                      <p className="buildpan_icon_text"> Build Application</p>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 buildpan-card">
                    <div className=" buildpan_icons_inner">
                      <img
                        src={icdeployment}
                        alt="loading-image"
                        className="buildpan_icon"
                      />
                      <p className="buildpan_icon_text">
                        Deployment on google and iphone
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="ship-section-stats">
        <div class="container">
          <div class="ship-section-wrapper">
            <div class="ship-section-wrapper-item">
              <p class="highlight">75000+</p>
              <span class="description">Minutes Saved</span>
            </div>
            <div class="ship-section-wrapper-item">
              <p class="highlight">400+</p>
              <span class="description">Application Deployed</span>
            </div>
            <div class="ship-section-wrapper-item">
              <p class="highlight">42%</p>
              <span class="description">Faster Deployment</span>
            </div>
            <div class="ship-section-wrapper-item">
              <p class="highlight">30%</p>
              <span class="description">Of Less Operational Costs</span>
            </div>
          </div>
        </div>
      </section>

      <section className="our-clients">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h1 className="gradient-heading-lg-dark">Our Esteemed Clients</h1>
              <div className="customer-logos slider">
                <Slider {...settings}>
                  <div>
                    <div className="slide slider_img">
                      <img src={jugnoo} />
                    </div>
                  </div>
                  <div>
                    <div className="slide slider_img">
                      <img src={Catapult} />
                    </div>
                  </div>
                  <div>
                    <div className="slide slider_img">
                      <img src={Lgfit} />
                    </div>
                  </div>
                  <div>
                    <div className="slide slider_img">
                      <img src={Linkites} />
                    </div>
                  </div>
                  <div>
                    <div className="slide slider_img">
                      <img src={LTI} />
                    </div>
                  </div>
                  <div>
                    <div className="slide slider_img">
                      <img src={Polishme} />
                    </div>
                  </div>
                  <div>
                    <div className="slide slider_img">
                      <img src={Seractive01} />
                    </div>
                  </div>
                  <div>
                    <div className="slide slider_img">
                      <img src={Shirali} />
                    </div>
                  </div>

                  <div>
                    <div className="slide slider_img">
                      <img src={toch} />
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="testimonials">
        <div className="container">
          <h1 className="gradient-heading-lg-dark">Testimonials</h1>
          <div className="slideshow-container">
            <Slider {...TestimonialsSlider}>
              {testimonail.map((item, ind) => {
                // console.log(item);
                return (
                  <div key={ind}>
                    <p className="mySlides-para">{item.content}</p>
                    <h6 className="text-bold">{item.clientName}</h6>
                    <h6 className="text-bold">{item.designation}</h6>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
