import React, { useEffect } from "react";
import casestudyBanner from "../../Images/pricing-bg.png";
const Pricing = () => {
    // useEffect (()=>{
    //     var header = document.getElementById("pricing-card");
    //     var btns = header.getElementsByClassName("pricing-card");
    //     for (var i = 0; i < btns.length; i++) {
    //       btns[i].addEventListener("click", function () {            
    //         var current = document.getElementsByClassName("active");
           
    //         current[0].className = current[0].className.replace(" active", "");
    //         this.className += " active";
    //       });
    //     }
    // },[]
    // )
  return (
    <>
      <section className="pricing-banner">
        <div className="banner-img-wrapper">
          <div className="banner-img">
            <img src={casestudyBanner} />
          </div>
          <div className="banner-text">
            <h2>Plans & Pricing</h2>
            <p>
            Your brain loves simple, straight-forward pricing that’s honest and human. We couldn’t agree more. We believe it should not cost a lot to take ownership of your team’s collective knowledge.
            </p>
          </div>
        </div>
      </section>
     <section className="pricing-wrapper" id="pricing-card">
        <div className="container">
            <div className="row">
                <div className="col-md-4 mb-4">
                    <div className="pricing-card">
                        <div>
                        <h4>Basic</h4>
                        <h1>$05<span>/mo</span></h1>
                        <p>100 Mins build time</p>
                        </div>
                        <div className="read-more">
                            <a href="https://app.buildpan.com/">Get Started</a>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 mb-4">
                    <div className="pricing-card">
                     <div>
                     <h4>Professional</h4>
                        <h1>$07<span>/mo</span></h1>
                        <p>300 Mins build time</p>
                     </div>
                        <div className="read-more">
                            <a href="https://app.buildpan.com/">Get Started</a>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 mb-4">
                    <div className="pricing-card">
                       <div>
                       <h4>Custom Plan</h4>
                        <p>We can help you decide the best for your company based on a lot of factors and other cool stuff that I’m going to write about</p>
                       </div>
                        <div className="read-more">
                            <a href="https://app.buildpan.com/">Get Started</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     </section>
    </>
  );
};

export default Pricing;
