import * as React from 'react';
import logo from './logo.svg';
import './App.css';
import './fonts/Poppins/stylesheet.css'
import Header from './Component/Common/Header';
import Footer from './Component/Common/Footer';
import { BrowserRouter, Routes,Route } from "react-router-dom"
import Home from './Component/Pages/Home';
import About from './Component/Pages/About';
import Blog from './Component/Pages/Blog';
import Pricing from './Component/Pages/Pricing';
import BlogView from './Component/Pages/blog-view';
import Careers from './Component/Pages/Career';
import Feature from './Component/Pages/Feature';
import Casestudy from './Component/Pages/Casestudy';
import Login from './Component/Pages/Login';
import Signup from './Component/Pages/signUp';
import PrivacyPolicy from './Component/Pages/Privacy-Policy';
import TermsCondition from './Component/Pages/Terms-Condition';
import Security from './Component/Pages/Security';
import ContactUs from './Component/Pages/contact';
import FAQ from './Component/Pages/Faq';
import store from './store/store'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Provider } from 'react-redux';

function App() {
  return (
    <>
    <Provider store={store}>
      <BrowserRouter>
        <Header />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blogView/:id" element={<BlogView />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/feature" element={<Feature />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/casestudy" element={<Casestudy />} />
          <Route path="/login" element={<Login />} />
          <Route path="/Signup" element={<Signup />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-condition" element={<TermsCondition />} />
          <Route path="/security" element={<Security />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/faq" element={<FAQ />} />
          {/* <Route path="/signUp" element={<SignUP />} /> */}
        </Routes>
        <Footer />
      </BrowserRouter>  
      </Provider>
      </> 
  );
}

export default App;
