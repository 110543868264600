import React, { useEffect } from "react";
const TermsCondition = () => {

  return (
    <>
        <section class="terms_conditions-wrapper">
        <div class="container">
            <div>
                <h1 class="heading">Terms and Conditions</h1>
            </div>
            <p>Effective Date: 10/01/2020</p>
            <p>1. Acceptance of Terms</p>
            <p>By accessing or using the Soft Launch Pte Ltd’s CICD platform ("we," "us," "our '', or BuildPan), you agree
                to be bound by these Terms and Conditions. If you do not agree with any part of these terms, please do not
                use our services.</p>
            <p>2. Use of Services</p>
            <p>2.1. Eligibility:</p>
            <ul>
                <li>You must be at least 10 years old to use our services. By using our platform, you represent and warrant
                    that you meet this eligibility requirement.
                </li>
            </ul>
            <p>2.2. Account Registration:</p>
            <ul>
                <li>You are responsible for maintaining the confidentiality of your account information. Any activity under
                    your account is your responsibility.
                </li>
            </ul>
            <p>3. CICD Platform Services</p>
            <p>3.1. Description:</p>
            <ul>
                <li>Our platform provides Continuous Integration and Continuous Deployment (CICD) services for mobile app
                    development. We reserve the right to modify or discontinue any part of the service without notice.
                </li>
            </ul>
            <p>3.2. User Conduct:</p>
            <ul>
                <li>Users agree not to engage in any activity that may interfere with the proper functioning of the platform
                    or violate these terms.
                </li>
            </ul>
            <p>4. Intellectual Property</p>
            <p>4.1. Ownership:</p>
            <ul>
                <li>All content and materials on our platform, unless otherwise stated, are the property of Softlaunch Pte
                    Ltd. Users may not use, reproduce, or distribute any content without our explicit permission.
                </li>
            </ul>
            <p>5. Payment and Billing</p>
            <p>5.1. Fees:</p>
            <ul>
                <li>
                    If applicable, fees for our services will be outlined on our website. By using our paid services, you
                    agree to pay all applicable fees.
                </li>
            </ul>
            <p>5.2. Payment Information:</p>
            <ul>
                <li>
                    You agree to provide accurate and complete payment information. Failure to do so may result in the
                    suspension or termination of your account.
                </li>
            </ul>
            <p>6. Privacy Policy</p>
            <p>Our Privacy Policy outlines how we collect, use, and protect your personal information. By using our
                services, you agree to the terms outlined in the Privacy Policy.
            </p>
            <p>7. Limitation of Liability</p>
            <p>To the extent permitted by law, Softlaunch Pte Ltd shall not be liable for any indirect, incidental, special,
                consequential, or punitive damages.</p>
            <p>8. Changes to Terms</p>
            <p>We reserve the right to update these terms at any time. Continued use of our services after changes
                constitute acceptance of the modified terms.</p>
            <p>9. Contact Us</p>
            <p>If you have any questions, concerns, or requests regarding these Terms and Conditions, please contact us at <a href="mailto:hello@buildpan.com">hello@buildpan.com</a>.</p>
        </div>
    </section>
    </>
  );
};

export default TermsCondition;
