import React from "react";

const Login = () => {
  return (
    <>
      <section class="login">
        <div class="container">
          <div class="login-wrapper">
            <div class="login-titles">
              <h1>Sign in</h1>
              <p class="form-subtitle">Log in to access your dashboard</p>
            </div>
            <div class="main-agileinfo">
              <div class="agileits-top">
                <form action="#" method="post">
                <input
                    class="text contact-feild"
                    type="text"
                    name="Username"
                    placeholder="Enter name"
                    required=""
                  />
                  <input
                    class="text email contact-feild"
                    type="text"
                    name="Password"
                    placeholder="Password"
                    required=""
                  />

                  <input
                    class="text w3lpass contact-feild"
                    type="password"
                    name="password"
                    placeholder="Confirm Password"
                    required=""
                  />
                  <div class="wthree-text">
                    <a href="#" class="login-forgotpw">
                      Forgot password?
                    </a>
                    <label class="anim">
                      <p class="checkbox-text">
                        I Agree To The Terms &amp; Conditions
                      </p>
                      <input type="checkbox" class="checkbox" />
                      <span class="checkmark"></span>
                    </label>
                    <div class="clear"> </div>
                  </div>
                  <input type="submit" value="Sign in" class="login_btn" />
                </form>
                <p class="sign-text ">
                  Don't have an Account?{" "}
                  <a href="/Signup">
                    <b>Sign Up</b>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
