import React, { useEffect } from "react";
import careerBanner from "../../Images/Career/career-bg.png";
import bottomGroupbg from "../../Images/features/bottom-group-bg.png";
const Careers = () => {
  return (
    <>
      <section className="career-banner">
        <div className="banner-img-wrapper">
          <div className="banner-img">
            <img src={careerBanner} />
          </div>
          <div className="banner-text">
            <h2>Careers</h2>
            <p>
              Discover exciting opportunities to grow, learn, and make a
              difference in a dynamic and supportive work environment
            </p>
          </div>
        </div>
      </section>
      <section className="jobs-details">
        <div className="container">
          <div className="jobs-list">
            <h3>Let’s Connect!!!!</h3>
            <p>
              If you enjoy tackling complex challenges and are dedicated to
              working hard, we would love to connect with you. Please share your
              resume at <a href="mailto:hr@buildpan.com">hr@buildpan.com</a>
            </p>
          </div>
        </div>
      </section>
      <section className="stay-up-block">
        <img src={bottomGroupbg} className="group-bg" />
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5">
              <h2>Stay Up to Date</h2>
              <p>
                Register to our blog updates newsletter to receive the latest
                content in your inbox.
              </p>
            </div>
            <div className="col-lg-7">
              <div className="mail-input">
                <input type="email" placeholder="Enter your mail id" />
                <button>Submit</button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Careers;
