import React, { useEffect } from "react";
import IndiaImg from "../../Images/india.jpg"
import SingaporeImg from "../../Images/singapor.jpg"
const ContactUs = () => {

  return (
    <>
    <section class="terms_conditions-wrapper">
     <div className="container">
     <div className="text-center">
                <h1 class="heading">Contact Us</h1>
            </div>
           <div className="row justify-content-center">
           <div className="col-lg-5 col-md-6">
           <div className="contact-details">
          <div className="contact-img">
                <img src={IndiaImg}/>
            </div>
            <h6>India Office:</h6>
                <p>BuildFly Private Limited:</p>
                <p>6th Floor, Building No 1, Right Side, </p>
                <p>Crystal IT Park, Indore, </p>
                <p>Madhya Pradesh 452001</p> 
          </div>
            </div>
            <div className="col-lg-5 col-md-6">
            <div className="contact-details">
            <div className="contact-img">
                <img src={SingaporeImg}/>
            </div>
                <h6>Singapore Office:</h6>
                <p>Softlaunch Pte Ltd:</p>
                <p>114 Lavender Street, CT Hub</p>
                <p>#11-83 (Lobby 1)</p>
                <p>Singapore 338729</p>
            </div>
            </div>
           </div>
     </div>
    </section>
    </>
  );
};

export default ContactUs;
