import React, { useEffect } from "react";
import bannerImg from "../../Images/about/about-bg.png";
import StoryImg from "../../Images/about/our-story.png";
import Slider from "react-slick";
import BlogImg from "../../Images/about/quick-setup.png";
import sliderOne from "../../Images/about/code-break.png";
import sliderTwo from "../../Images/about/code-break-2.png";
import sliderThree from "../../Images/about/code-break.png";
import sliderFour from "../../Images/about/code-break-2.png";
import storyBg from "../../Images/about/story-bg.png";
import AboutBanner from "../../Images/about-banner.png";
import bottomBg from "../../Images/about/bottom-bg.png";
import { useState } from "react";
const About = () => {
  let contents =
    "Almost every software development company distributes mobile applications but they need to follow manual continuous integration and continue deployment to launch their mobile application. Buildpan is a cloud based Continuous Integration, Continuous Deployment, and User Feedback platform for iOS and Android development teams. Where CI practices regularly";

  const [readmore, setreadmore] = useState(false);

  const BlogSlider = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const RightSlider = {
    slidesToShow: 2,
    slidesToScroll: 2,
    autoplay: false,
    autoplaySpeed: 5000,
    arrows: false,
    dots: false,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <>
      <section className="about-banner-container">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-lg-6">
              <div className="banner-text">
                <h2>About us</h2>
                <h6>Innovation Is Seeing What Everybody Has Seen And <span>Thinking What Nobody Has Thought</span></h6>
                <div className="about-para">
                  <p>BuildPan allows teams to rapidly build and deploy quality apps. Our mission is to empower teams everywhere to build and deploy tested apps in a production environment. BuildPan uses GitID to pull the repository and create auto-build along with the deployment. Our goal is to build mission-critical solutions that lead to enhancement and efficiencies.
                    Cross-Platform projects in React Native or Ionic, can benefit from our best-in-class error tracking tool for both Android and iOS mobile applications.</p>
                </div>
                <div className="join-us">
                  <a href="https://app.buildpan.com/">Join Us</a>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <img src={AboutBanner} className="about-banner-img" />
            </div>
          </div>
        </div>
      </section>

      <section className="story-wrapper">
        <img src={storyBg} className="storyBg" />
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <h2>Our Story</h2>
              <p>
                No successful business comes without quality app development.
                Building quality software and shipping it quickly have become
                the core engine of value creation in companies across all
                industries. Buildpan allows teams to quickly bundle the build
                and release it whenever it is ready to go live. Compared to
                other players in the market, Buildpan is fast and affordable. It
                shortens the deployment cycle and detects the critical bugs.
              </p>
            </div>
            <div className="col-lg-6">
              <img src={StoryImg} className="story-right" />
            </div>
          </div>
        </div>
      </section>
      <section className="blog-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <Slider {...BlogSlider}>
                <div>
                  <img src={BlogImg} />
                </div>
                <div>
                  <img src={BlogImg} />
                </div>
                <div>
                  <img src={BlogImg} />
                </div>
                <div>
                  <img src={BlogImg} />
                </div>
              </Slider>
            </div>
            <div className="col-lg-6 blog-text">
              <h2>Quick Setup in less than five minutes</h2>
              <p>
                Login with any Git account, fetch your latest repos and start
                creating your applications. Install our SDK (we work with iOS,
                Android, React Native, Swift and various others) and check the
                latest crash analysis. Is your data secure? Buildpan provides
                high-quality security features on all accounts, regardless of
                your plan.
              </p>
              <p>We provides:</p>
              <ul>
                <li>Two-Factor Auth</li>
                <li>Single Sign-On support</li>
                <li>Organization Audit Log</li>
                <li>Privacy</li>
                <li>SSL encryption</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="code--block">
        <div className=" code-slider">
          <Slider {...RightSlider}>
            <div>
              <img src={sliderOne} />
            </div>
            <div>
              <img src={sliderTwo} />
            </div>
            <div>
              <img src={sliderThree} />
            </div>
            <div>
              <img src={sliderFour} />
            </div>
          </Slider>
        </div>
        <div className="container">
          <div className="code-text">
            <h2>When your code breaks:</h2>
            <p>
              Buildpan is here to help Code written by the best developers
              contain bugs, and many of these bugs get shipped to production.
              This is unavoidable. Buildpan is here to assist. You can identify
              and fix bugs before your code goes into production.
            </p>
          </div>
        </div>
      </section>
      <section className="work-block">
        <img src={bottomBg} className="bottomBg" />
        <div className="container">
          <h2>Work with us</h2>
          <p>
            We work with teams of all sizes to set up Buildpan to fit their
            specific needs. Let us know what you’re building and we’ll see how
            we can help.
          </p>
          <div className="learn-more">
            <a href="https://app.buildpan.com/">Get Started</a>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
