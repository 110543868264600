import React, { useEffect } from "react";

const Security = () => {

  return (
    <>
     
    <section class="terms_conditions-wrapper">
   <div className="container">
   <div>
                <h1 class="heading">Security</h1>
            </div>
            <p>Effective Date: 10/01/2020</p>
            <p>At Softlaunch Pte Ltd, we prioritize the security of your data and strive to provide a safe environment for your mobile app development. Below are the key security measures we have implemented:</p>
            <p>1. Encryption and Data Protection</p>
            <p>1.1. Data Transmission:</p>
            <ul>
                <li>All data transmitted between your device and our servers is encrypted using industry-standard protocols, such as TLS, to ensure the confidentiality and integrity of your information.</li>
            </ul>
            <p>1.2. Data Storage:</p>
            <ul>
                <li>Data at rest is stored using encryption mechanisms to safeguard it from unauthorized access.</li>
            </ul>
            <p>2. Access Controls</p>
            <p>2.1. Authentication:</p>
            <ul>
                <li>Multi-factor authentication is enforced to add an extra layer of protection to user accounts.</li>
            </ul>
            <p>2.2. Authorization:</p>
            <ul>
                <li>Access to sensitive information and platform features is strictly controlled, and users have access only to the resources necessary for their roles.</li>
            </ul>
            <p>3. Infrastructure Security</p>
            <p>3.1. Secure Hosting:</p>
            <ul>
                <li>Our platform is hosted on secure and reputable cloud infrastructure providers that adhere to industry-leading security standards.</li>
            </ul>
            <p>3.2. Regular Audits:</p>
            <ul>
                <li>Periodic security audits and assessments are conducted to identify and address potential vulnerabilities.</li>
            </ul>
            <p>4. Continuous Monitoring</p>
            <p>4.1. Real-time Monitoring:</p>
            <ul>
                <li>We employ advanced monitoring tools to detect and respond to any unusual activities or potential security threats promptly.</li>
            </ul>
            <p>4.2. Incident Response:</p>
            <ul>
                <li>In the event of a security incident, we have a well-defined incident response plan in place to mitigate and address the issue promptly.</li>
            </ul>
            <p>Compliance and Certifications</p>
            <p>5.1. Regulatory Compliance:</p>
            <ul>
                <li>We adhere to relevant data protection and privacy regulations to ensure the lawful and ethical handling of user data.</li>
            </ul>
            <p>5.2. Certifications:</p>
            <ul>
                <li>Our security practices are aligned with industry-recognized certifications to demonstrate our commitment to maintaining a secure platform.</li>
            </ul>
            <p>6. User Education</p>
            <p>6.1. Training Resources:</p>
            <ul>
                <li>We provide educational resources to help users understand and implement best practices for secure usage of our platform.</li>
            </ul>
            <p>7. Reporting Security Concerns</p>
            <p>If you identify any security vulnerabilities or have concerns regarding the security of our platform, please contact our team at <a href="mailto:hello@buildpan.com.">hello@buildpan.com.</a>.</p>
   <p>8. Updates to Security Measures</p>
   <p>We continuously evaluate and enhance our security measures. Any updates or changes will be communicated through our platform and, if necessary, on this security page.</p>
   <p>Your trust and the security of your data are of utmost importance to us. If you have any questions or require further information, please contact us at <a href="mailto:hello@buildpan.com.">hello@buildpan.com</a>.</p>

       </div>
    </section>
    </>
  );
};

export default Security;
