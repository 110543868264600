import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import logo from "../../logo.svg";

import logos from "../../Images/BULDPAN.CLOUD BLACK.svg";

const Header = () => {
  const[data,setdata] = useState([])
  const [menuactive, setactive] = useState(false);

  const changeactive = () => {
    setactive(!menuactive);
    console.log(menuactive);
    window. scrollTo({ top: 0, behavior: 'smooth', }); 
  };
  useEffect(() => {
    var a = document.querySelectorAll(".navbar-nav a");
    for (var i = 0, length = a.length; i < length; i++) {
      a[i].onclick = function () {
        var b = document.querySelector(".navbar-nav li.active");
        if (b) b.classList.remove("active");
        this.parentNode.classList.add("active");
      };
    }
  }, [menuactive]);

  return (
    <>
      <header className="header">
        <div className="container">
          <nav className="navbar navbar-expand-lg navbar-dark p-0">
            <div className="container-fluid navbar-nav" >
              <Link to="/" className="navbar-brand" onClick={changeactive}>
                <img src={logos} />
              </Link>
              <button
                class="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#mynavbar"
                aria-expanded={menuactive ? true : false}
                onClick={changeactive}
              >
                <span class="navbar-toggler-icon"></span>
              </button>
              <div
                className={
                  menuactive
                    ? "primary-menu-container collapse show"
                    : "primary-menu-container "
                }
                id="mynavbar"
              >
                <ul className="navbar-nav ">
                  <li className="nav-item " onClick={changeactive}>
                    <Link to="/about">About</Link>
                  </li>
                  <li className="nav-item" onClick={changeactive}>
                    <Link to="/feature">Features</Link>
                  </li>
                  <li className="nav-item" onClick={changeactive}>
                    <Link to="/blog">Blogs</Link>
                  </li>
                  <li className="nav-item" onClick={changeactive}>
                    <Link to="/careers">Careers</Link>
                  </li>
                  <li className="nav-item" onClick={changeactive}>
                    <Link to="/casestudy">Case Studies</Link>
                  </li>
                  <li className="nav-item" onClick={changeactive}>
                    <Link to="/pricing" className="demo">
                      Pricing
                    </Link>
                  </li>
                  <li className="nav-item" onClick={changeactive}>
                    <a href="https://app.buildpan.com/">Login</a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </header>
    </>
  );
};

export default Header;
