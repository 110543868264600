import React, { useEffect } from "react";
import IndiaImg from "../../Images/india.jpg"
import SingaporeImg from "../../Images/singapor.jpg"
const FAQ = () => {

    return (
        <>
            <section class="terms_conditions-wrapper">
                <div className="container">
                    <div className="text-center">
                        <h1 class="heading">Frequently Asked Questions (FAQ)</h1>
                    </div>
                    <div className="faqs-block">
                        <div class="accordion mb-4" id="accordionExample">
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingOne">
                                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        1. What is BuildPan?
                                    </button>
                                </h2>
                                <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        BuildPan is a Continuous Integration and Continuous Deployment (CICD) platform designed specifically for mobile app development. We streamline the software development lifecycle, allowing teams to build, test, and deploy mobile applications efficiently.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingTwo">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        2. How does BuildPan work?
                                    </button>
                                </h2>
                                <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        Our platform integrates seamlessly into your mobile app development process. You connect your version control system, and BuildPan automates the build, test, and deployment processes, ensuring a smooth and efficient workflow.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingThree">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                        3. What platforms and languages does BuildPan support?
                                    </button>
                                </h2>
                                <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        BuildPan supports a wide range of mobile app development platforms and programming languages, including but not limited to iOS (Swift), Android (Java/Kotlin), and cross-platform frameworks (e.g., React Native, Flutter).
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingFour">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                        4. Is BuildPan suitable for both small startups and large enterprises?
                                    </button>
                                </h2>
                                <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        Absolutely! BuildPan is designed to scale, making it suitable for both small development teams and large enterprise environments. Our flexible infrastructure caters to diverse project sizes.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingFive">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                        5. What security measures are in place to protect my data?
                                    </button>
                                </h2>
                                <div id="collapseFive" class="accordion-collapse  collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        We prioritize the security of your data. Softlaunch Pte Ltd implements industry-standard encryption for data transmission and storage. Multi-factor authentication adds an extra layer of protection, and our infrastructure undergoes regular security audits.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingSix">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                        6. Can I integrate BuildPan with my existing tools and workflows?
                                    </button>
                                </h2>
                                <div id="collapseSix" class="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        Yes, BuildPan is designed for easy integration. We provide support for popular version control systems, issue trackers, and other development tools. Custom integrations can also be implemented through our APIs.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingSeven">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                        7. How is customer support handled at Softlaunch Pte Ltd?
                                    </button>
                                </h2>
                                <div id="collapseSeven" class="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        Our dedicated support team is ready to assist you. You can reach out to us via hello@buildpan.com, and we aim to respond to inquiries promptly. Additionally, we offer documentation and tutorials to help you navigate the platform.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingEight">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false " aria-controls="collapseEight">
                                        8. What is the pricing model for BuildPan?
                                    </button>
                                </h2>
                                <div id="collapseEight" class="accordion-collapse collapse" aria-labelledby="headingEight" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        Details about our pricing plans can be found on our Pricing page. We offer a range of plans to accommodate different team sizes and project requirements.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header " id="headingNine">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                                        9. Can I try BuildPan before committing to a subscription?
                                    </button>
                                </h2>
                                <div id="collapseNine" class="accordion-collapse collapse" aria-labelledby="headingNine" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        Certainly! We offer a free trial period for you to explore the features and capabilities of BuildPan. Sign up on our website to get started.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingTenth">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTenth" aria-expanded="false" aria-controls="collapseTenth">
                                        10. How often is BuildPan updated or enhanced?
                                    </button>
                                </h2>
                                <div id="collapseTenth" class="accordion-collapse collapse" aria-labelledby="headingTenth" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        We continuously strive to improve and enhance BuildPan. Updates and new features are rolled out regularly, and we value user feedback in shaping the future development of our platform.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p>For any other questions or specific inquiries, feel free to contact us at <a href="mailto:hello@buildpan.com">hello@buildpan.com</a>.</p>
                    </div>
                </div>

            </section>
        </>
    );
};

export default FAQ;
