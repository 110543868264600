import React, { useEffect, useState } from "react";
import bannerImg from "../../Images/blog/blog-bg.png";
import cicdImg from "../../Images/blog/ci-cd.png";
import Slider from "react-slick";
import blogBanner from "../../Images/blog/article-one.png";
import user from "../../Images/blog/user.svg";
import blogViewOne from "../../Images/blog/blog-view-one.png";
import blogViewTwo from "../../Images/blog/blog-view-two.png";
import blogViewThree from "../../Images/blog/blog-view-three.png";
import articleSix from "../../Images/blog/article-six.png";
import articleSeven from "../../Images/blog/article-seven.png";
import articleEight from "../../Images/blog/article-eight.png";
import articleNine from "../../Images/blog/article-nine.png";
import articleTenth from "../../Images/blog/article-ten.png";
import articleEleven from "../../Images/blog/article-eleven.png";
import articleTwelth from "../../Images/blog/article-twelth.png";
import articleThirteen from "../../Images/blog/article-thirteen.png";
import articleFourteen from "../../Images/blog/article-fourteen.png";
import articleSixteen from "../../Images/blog/article-sixteen.png";
import articleSeventeen from "../../Images/blog/article-seventeen.png";
import articlefifteen from "../../Images/blog/article-fifteen.png";
import cicdSlide from "../../Images/blog/cicd-slide-thee.png";
import bottomGroupbg from "../../Images/features/bottom-group-bg.png";
import { getblogByid,removep } from "../../Api";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment/moment";

const BlogView = () => {
  const [blogdetail, setblogdetail] = useState([]);
  const BlogViewSlider = {
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 5000,
    arrows: false,
    dots: true,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const { id } = useParams();
  const blogdetails = async (id) => {
    const blogd = await getblogByid(id);
    setblogdetail(blogd);
  };
  useEffect(() => {
    blogdetails(id);
  }, []);
  return (
    <>
      <section className="blog-view-banner">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-7">
              <h2>{blogdetail.title}</h2>
              <div className="date">
                <img src={user} />
                <p>
                  Posted on {moment(blogdetail.created_at).format("MMMM")} {""}
                  {moment(blogdetail.created_at).format("D")},
                  {moment(blogdetail.created_at).format("Y")}
                </p>
              </div>
            </div>
            <div className="col-lg-5">
              <img src={blogdetail.image} className="right-img" />
            </div>
          </div>
        </div>
      </section>
      <section className="blog-view-text">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-7">
              <h4> {blogdetail.title}</h4>
              {blogdetail.content}
            </div>
          </div>
        </div>
      </section>
      {/* <section className="article-slider">
        <div className="container">
          <h2>Recent Articles</h2>
          <div className="row">
            <Slider {...BlogViewSlider}>
              <div className="col-lg-4 article-list">
                <div className="card">
                  <div className="article-img">
                    <img src={blogdetail.image} />
                  </div>
                  <div className="blog-text">
                    <h4>Continuous Deployement</h4>
                    <p>Continuous Integration (CI) & Continuous.....</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 article-list">
                <div className="card">
                  <div className="article-img">
                    <img src={blogViewTwo} />
                  </div>
                  <div className="blog-text">
                    <h4>Continuous Deployement</h4>
                    <p>Continuous Integration (CI) & Continuous.....</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 article-list">
                <div className="card">
                  <div className="article-img">
                    <img src={blogViewThree} />
                  </div>
                  <div className="blog-text">
                    <h4>Continuous Deployement</h4>
                    <p>Continuous Integration (CI) & Continuous.....</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 article-list">
                <div className="card">
                  <div className="article-img">
                    <img src={blogViewOne} />
                  </div>
                  <div className="blog-text">
                    <h4>Continuous Deployement</h4>
                    <p>Continuous Integration (CI) & Continuous.....</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 article-list">
                <div className="card">
                  <div className="article-img">
                    <img src={blogViewTwo} />
                  </div>
                  <div className="blog-text">
                    <h4>Continuous Deployement</h4>
                    <p>Continuous Integration (CI) & Continuous.....</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 article-list">
                <div className="card">
                  <div className="article-img">
                    <img src={blogViewThree} />
                  </div>
                  <div className="blog-text">
                    <h4>Continuous Deployement</h4>
                    <p>Continuous Integration (CI) & Continuous.....</p>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </section> */}
      <section className="stay-up-block">
        <img src={bottomGroupbg} className="group-bg" />
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5">
              <h2>Stay Up to Date</h2>
              <p>
                Register to our blog updates newsletter to receive the latest
                content in your inbox.
              </p>
            </div>
            <div className="col-lg-7">
              <div className="mail-input">
                <input type="email" placeholder="Enter your mail id" />
                <button>Submit</button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BlogView;
