import React, { useEffect, useState } from "react";
import featureBanner from "../../Images/features/features-bg.png";
import featuresOne from "../../Images/features/effectlesssetup.gif";
import featuresTwo from "../../Images/features/TeamOnboarding.gif";
import featuresThree from "../../Images/features/supportplatform -.gif";
import featuresFour from "../../Images/features/OtherAppIntegration.gif";
import featuresFive from "../../Images/features/features-five.png";
import featuresSix from "../../Images/features/features-six.png";
import rightBg from "../../Images/features/right-bg.png";
import leftBg from "../../Images/features/left-bg.png";
import topbottombg from "../../Images/features/top-bottom-bg.png";
import bottomGroupbg from "../../Images/features/bottom-group-bg.png";
import { getfeature,removep } from "../../Api";
const Feature = () => {
  const [featue, setfeature] = useState([]);
  
  const allfeature = async () => {
    const all = await getfeature();
    setfeature(all);
  };

  useEffect(() => {
    allfeature();
  }, []);
  return (
    <>
      <section className="features-banner">
        <div className="banner-img-wrapper">
          <div className="banner-img">
            <img src={featureBanner} />
          </div>
          <div className="banner-text">
            <h2>Features</h2>
            <p>
              Effortless Setup: Setup a continuous integration system for your
              iOS and Android projects in just minutes.
            </p>
          </div>
        </div>
      </section>
      <section className="features-wrapper">
        {featue.map((item, ind) => {
         
 
            if (ind % 2 == 0) {
              return (
                <div className="features-list">
                  <img src={rightBg} className="right-bg" />
                  <div className="container">
                    <div className="row align-items-center">
                      <div className="col-lg-6">
                        <h3>{item.title}</h3>
                        <p>{removep(item.content)}</p>
                        <div className="explore-btn">
                          <a href="https://app.buildpan.com/">Explore Now</a>
                          {ind}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <img src={item.image} className="featue-img" />
                      </div>
                    </div>
                  </div>
                </div>
              );
            } else {
              return (
                <div className="features-list">
                  <img src={leftBg} className="left-bg" />
                  <div className="container">
                    <div className="row features-block align-items-center">
                      <div className="col-lg-6">
                        <h3>{item.title}</h3>
                        <p>{removep(item.content)}</p>
                        <div className="explore-btn">
                          <a href="https://app.buildpan.com/">Explore Now</a>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <img src={item.image} className="featue-img" />
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
       
        })}

      </section>
    </>
  );
};

export default Feature;
