import { ORIGIN } from "./Config";
import axios from "axios";

export const getblogbypage = (page) => {
  return new Promise(async (resolve) => {
    const url = ORIGIN + "/api/getblog/" + page;
    console.log(url);
    const res = await axios.post(url);
    resolve(res.data);
  });
};

export const getblogByid = (id) => {
  return new Promise(async (resolve) => {
    const url = ORIGIN + "/api/editblog/"+id;
    const res = await axios.post(url);
    resolve(res.data);
  });
};

export const getfeature = ()=>{
    return new Promise(async (resolve) => {
        const url = ORIGIN + "/api/getfeature";
        const res = await axios.get(url);
        resolve(res.data);
      });
}

export const getcashstudy = ()=>{
  return new Promise(async (resolve) => {
      const url = ORIGIN + "/api/showCaseStudy";
      const res = await axios.get(url);
      resolve(res.data);
    });
}

export const removep = ($string)=>{
  $string = $string.replace('<p>','');
  $string = $string.replace('</p>','');
  return $string;
}

export const gettestimonail = ()=>{
  return new Promise(async (resolve) => {
      const url = ORIGIN + "/api/gettestimonal";
      const res = await axios.get(url);
      resolve(res.data);
    });
}